import React from 'react'
import styled from 'styled-components'
import { colors } from '../ui'

const StyledLabel = styled.div`
  background-color: ${colors.yellow};
  display: inline-block;
  padding: 4px 8px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 1.8rem;
  color: ${colors.gray};
`

export default function MiniLabel(props) {
  return <StyledLabel>{props.children}</StyledLabel>
}

import React, { useState } from 'react'
import styles from './email-footer.module.css'
import MiniLabel from './mini-label'

export default ({ data }) => {
  const [email, setEmail] = useState('')

  function onInputEmail({ target: { defaultValue } }) {
    setEmail(defaultValue)
  }

  return (
    <div className={styles.hero}>
      <div className={styles.prompt}>
        <MiniLabel>Journey East Zine</MiniLabel>
        <div style={{ marginBottom: '3rem' }}>
          Chinese recipes, stories, and food for thought, straight to your
          inbox.
        </div>
      </div>
      <form
        action="https://journeyeast.us4.list-manage.com/subscribe/post"
        method="POST"
        className={styles.emailForm}
      >
        <input
          type="hidden"
          name="u"
          defaultValue="9786728ce5b7b2c3b7e0fae52"
        />
        <input type="hidden" name="id" defaultValue="aaa8fa0b51" />
        <div
          className={styles.fieldShift}
          aria-label="Please leave the following three fields empty"
        >
          <label htmlFor="b_name">Name: </label>
          <input
            type="text"
            name="b_name"
            tabIndex="-1"
            defaultValue=""
            placeholder="Freddie"
            id="b_name"
          />

          <label htmlFor="b_email">Email: </label>
          <input
            type="email"
            name="b_email"
            tabIndex="-1"
            defaultValue=""
            placeholder="youremail@gmail.com"
            id="b_email"
          />

          <label htmlFor="b_comment">Comment: </label>
          <textarea
            name="b_comment"
            tabIndex="-1"
            placeholder="Please comment"
            id="b_comment"
          />
        </div>

        <input
          name="MERGE0"
          id="MERGE0"
          className={styles.email}
          type="text"
          onChange={onInputEmail}
          placeholder="email address"
          aria-label="Email Address"
        />
        <input
          type="submit"
          name="submit"
          value="Subscribe"
          className={styles.subscribe}
        ></input>
      </form>
    </div>
  )
}

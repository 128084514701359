import moment from 'moment'

export const colors = {
  yellow: '#feea89',
  gold: '#8d6f15',
  blue: '#1d5994',
  gray: '#373f49',
  white: '#fff',
  black: '#000',
}

export function getMonthYear(week) {
  return (
    moment(week.issueDate)
      .format('MMM')
      .toUpperCase() +
    ' ' +
    moment(week.issueDate).format('YYYY')
  )
}

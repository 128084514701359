import React from 'react'
import styled from 'styled-components'
import EmailFooter from '../components/email-footer'
import { colors } from '../ui'

const StyledFooter = styled.footer`
  background: ${colors.blue};
  color: ${colors.white};
  text-align: center;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;
`

const StyledMain = styled.div`
  background-color: ${colors.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
`

export default ({ data }) => (
  <StyledFooter>
    <EmailFooter />
    <StyledMain>Grace Kwan © 2020</StyledMain>
  </StyledFooter>
)
